import React, {FC} from 'react';
import { Grid, Typography, Button, Container, Box } from '@mui/material';
import patternImg from './pattern.svg';
import './styles.scss'

const BlogHero: FC = () => {
  return (
    <section className="blog-hero" style={{backgroundImage: `url(${patternImg})`}}>
      <Container>
        <Grid 
          container
          direction='row'
          >
          <Grid xs={12} sm={12} md={12} lg={12} py={4}>
            <Typography variant='h1' color="text.white">
              Blog de Ingenio Kushki
            </Typography>
            <Typography variant='body1' color="text.white">
            Permitimos que millones de ciudadanos y empresas tengan acceso a documentos y trámites en línea de forma segura, fácil y rápida.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default BlogHero