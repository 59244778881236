import React, {FC} from 'react';
import { Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image';
import { Grid, Typography, Button, Container, Box } from '@mui/material';
// import { BlogAuthor } from '../BlogPost';

interface BlogEntryProps {
  slug: string;
  image: string;
  title: string;
  excerpt: string;
  date: string;
}

const BlogEntry: FC<BlogEntryProps> = ({
    slug,
    image,
    title,
    excerpt,
  }) => {
  return (
    <Container>
      <Grid container 
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Link to={`/blog/${slug}`}>
          <Box px={'1rem'}>
            <Box>
              <img src={image} alt="" width={'100%'}/>
            </Box>
            <Typography variant='h4' color='primary' mt={'0.5rem'}>
              {title}
            </Typography>
            <Typography variant='body2' color='primary' mt={'0.8rem'}>
            <p
              dangerouslySetInnerHTML={{
                __html: excerpt,
              }}
              />
            </Typography>
          </Box>
        </Link>
      </Grid>
    </Container>
  )
}

export default BlogEntry